import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const AsureSection = () => {
  const data = useStaticQuery(graphql`
    query {
      asureMock: file(relativePath: { eq: "products/asure-mockup.png" }) {
        publicURL
      }
      asureCar: file(relativePath: { eq: "products/asure-car.png" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-asure section__left-chamfer">
      <div className="container">
        <h2 className="title has-text-white">
          Seguro <br className="is-hidden-desktop" />
          Auto
        </h2>
        <p className="subtitle is-hidden-desktop">
          Fácil acesso aos telefones de todas as seguradoras em casos de
          emergência.
        </p>
        <p className="subtitle is-hidden-mobile">
          Acesse os telefones da sua seguradora em <br />
          caso de emergência e seja lembrado quando <br />o seu seguro estiver
          próximo de vencer.
        </p>

        <div className="images">
          <img className="asure-mockup" src={data.asureMock.publicURL} alt="" />
          <div className="asure-mockup-color"></div>
          <img className="asure-car" src={data.asureCar.publicURL} alt="" />
        </div>
      </div>
    </section>
  )
}

export default AsureSection
